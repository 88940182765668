var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.totalPages > 1)?_c('div',{staticClass:"ais-Pagination pagination-product-grid"},[_c('transition-group',{staticClass:"ais-Pagination-list",attrs:{"name":"list-transition","tag":"ul"}},[_c('li',{key:"previous",staticClass:"ais-Pagination-item ais-Pagination-item--previousPage",class:{
        'ais-Pagination-item--disabled': _vm.previousDisabled
      },on:{"click":function($event){return _vm.navigate(_vm.currentPage - 1)}}},[_c('span',{staticClass:"ais-Pagination-link",attrs:{"aria-label":"Previous"}},[_vm._v("\n        ‹\n      ")])]),_vm._v(" "),_vm._l((_vm.items),function(item,key){return _c('li',{key:item,staticClass:"ais-Pagination-item",class:{
        'hidden md:block': [0, 4].includes(key),
        'ais-Pagination-item--selected': _vm.isCurrentPage(item)
      },on:{"click":function($event){return _vm.navigate(item)}}},[_c('a',{staticClass:"ais-Pagination-link relative"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.loading && _vm.loadingFor === 'PAGINATION' && _vm.isCurrentPage(item))?_c('span',{staticClass:"pagination-loader"},[_c('q-loader',{staticClass:"is-black is-small"})],1):_c('span',[_vm._v("\n            "+_vm._s(item)+"\n          ")])])],1)])}),_vm._v(" "),_c('li',{key:"next",staticClass:"ais-Pagination-item ais-Pagination-item--nextPage",class:{
        'ais-Pagination-item--disabled': _vm.nextDisabled
      },on:{"click":function($event){return _vm.navigate(_vm.currentPage + 1)}}},[_c('span',{staticClass:"ais-Pagination-link",attrs:{"aria-label":"Next"}},[_vm._v("\n        ›\n      ")])])],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }