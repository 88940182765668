<template>
  <div class="container px-4 md:px-8 my-16 md:my-32">
    <component
      :is="component"
      :fields="fields"
      :default-options="defaultOptions"
    />
  </div>
</template>

<script>
  import { computed } from '@nuxtjs/composition-api'
  import pickBy from 'lodash.pickby'
  import identity from 'lodash.identity'

  import Carousel from './ProductOverview/Carousel'
  import List from './ProductOverview/List'
  import { productFilters } from '@/constants/products'

  export default {
    props: {
      data: {
        type: Object,
        default: () => {},
        required: true
      }
    },
    setup (props) {
      const fields = computed(() => {
        return props.data.fields
      })

      const component = computed(() => {
        return {
          Carousel,
          List
        }[fields.value.format]
      })

      const levels = fields?.value.level?.map(level => level.fields.slug)

      const subjects = fields?.value.schoolSubject?.map(level => level.fields.title)
      const defaultOptions = {
        filters: pickBy({
          [productFilters.brand]: fields?.value.productType || null,
          [productFilters.educationType]: levels || null,
          [productFilters.subject]: subjects
        }, identity())
      }

      return {
        fields,
        component,
        defaultOptions
      }
    }
  }
</script>

<style scoped>

</style>
