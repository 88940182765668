<template>
  <div class="rich-text container px-4 md:px-8">
    <div class="w-full md:w-8/12 lg:w-6/12 mx-auto">
      <rich-text-content :content="fields.content" />
      <div
        v-if="fields.buttonLink"
        class="mt-10 text-center"
      >
        <e-button
          v-if="isRelativeUrl(fields.buttonLink)"
          tag="nuxt-link"
          :to="fields.buttonLink"
        >
          {{ fields.buttonText }}
        </e-button>
        <a
          v-else
          class="button button-primary"
          :href="fields.buttonLink"
          target="_blank"
        >
          {{ fields.buttonText }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>

  import isRelativeUrl from '@/mixins/isRelativeUrl'

  export default {
    mixins: [isRelativeUrl],
    props: {
      data: {
        type: Object,
        default: () => {}
      }
    },
    computed: {
      fields () {
        return this.data.fields
      }
    }
  }
</script>
