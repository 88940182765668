import { computed } from '@nuxtjs/composition-api'

export const useBanners = (products, banners, bannerPositions = [6, 16]) => {
    const bannersWithContentType = computed(() => {
       return banners.map((banner) => {
            return {
                ...banner.fields,
                contentType: 'CallToAction'
            }
        })
    })

    if (!bannersWithContentType.value || bannersWithContentType.value.length === 0) {
        return products
    }

    return computed(() => {
        bannersWithContentType.value.forEach((banner, i) => {
            if (bannerPositions[i] > products.value.length) {
                products.value.push(banner)
            } else {
                products.value.splice(bannerPositions[i], 0, banner)
            }
        })

        return products.value
    })
}
