<template>
  <card-base class="card-article">
    <nuxt-link :to="postLink">
      <card-image
        aspect-ratio="4-3"
        :image="image"
        :alt="alt"
      />
      <div class="card-content">
        <h3
          class="mb-4 font-primary text-base leading-normal"
          :title="fields.title"
        >
          {{ fields.title }}
        </h3>
        <div class="card-meta text-sm">
          <span class="text-gray-600">
            {{ fields.category.fields.title }}
          </span>
          <span class="ml-2 text-gray-400">
            {{ date }}
          </span>
        </div>
      </div>
    </nuxt-link>
  </card-base>
</template>

<script>

  import CardBase from '@/components/CardBase'
  import CardImage from '@/components/CardImage'

  export default {
    components: {
      CardBase,
      CardImage
    },
    props: {
      post: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        imageSettings: '?w=480&h=360&fm=jpg&fit=fill&fl=progressive&q=80'
      }
    },
    computed: {
      fields () {
        return this.post.fields
      },
      date () {
        if (!this.post.fields.publishDate || this.post.fields.category.fields.slug !== 'nieuws') return ''
        return this.$dateFns.format(this.post.fields.publishDate, 'dd MMM yyyy')
      },
      image () {
        if (!this.fields.featuredImage) return ''
        return 'https:' + this.fields.featuredImage.fields.file.url + this.imageSettings
      },
      alt () {
        if (!this.image) return ''
        return this.fields.featuredImage.fields.description
      },
      postLink () {
        return '/artikel/' + this.fields.slug
      }
    }
  }
</script>

<style lang="scss">

  .card-article {

    .card-content {
      @apply flex flex-col justify-between bg-white p-6 h-40;

      h3 {
        @apply overflow-hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }

    }

  }

</style>
