<template>
  <dropdown-base
    class="dropdown-filter"
    :class="{'is-filled': selected.length > 0}"
    :transition-name="transitionName"
    @show="showHandler"
    @hide="hideHandler"
  >
    <template
      #button
    >
      <transition
        name="fade"
        mode="out-in"
      >
        <q-loader
          v-if="loading"
          class="is-black"
        />
        <span
          v-else
          class="flex items-center"
        >
          {{ buttonLabel }}
          <q-icon name="chevron-down" />
        </span>
      </transition>
    </template>
    <template #default="slotProps">
      <div class="relative">
        <div class="mobile-header">
          <p class="text-lg font-bold">
            {{ label }}
          </p>
          <button
            class="button-close"
            @click="slotProps.toggle($event)"
          >
            <q-icon name="cross" />
          </button>
        </div>
        <div class="overflow-container overflow-y-auto">
          <ul class="p-6 pb-0">
            <li
              v-for="(option, i) in parsedOptions"
              :key="i"
              class="pb-6"
            >
              <e-checkbox
                :id="`${label}-checkbox-${i}`"
                class="w-full"
                :value="selected.includes(option.name)"
                @input="checkboxHandler(option.name)"
              >
                <span>
                  {{ option.name }}
                </span> <difficulty-level-stars
                  v-if="label === 'Moeilijkheidsniveau'"
                  :star-amount="option.value"
                />
              </e-checkbox>
            </li>
          </ul>
        </div>
        <div class="flex items-center justify-between bg-white border-t border-gray-200 p-4">
          <button
            class="button-clear"
            :disabled="selected.length === 0"
            @click="clear"
          >
            Wissen
          </button>
          <e-button @click="update(slotProps, $event)">
            Toon resultaten
          </e-button>
        </div>
      </div>
    </template>
  </dropdown-base>
</template>

<script>
  import DropdownFilter from '@/components/DropdownFilter'
  import { QLoader } from '@qikkeronline/qikker-ui'
  import DropdownBase from '@/components/DropdownBase'
  import DifficultyLevelStars from '@/components/DifficultyLevelStars'

  export default {
    components: {
      DropdownBase,
      QLoader,
      DifficultyLevelStars
    },
    props: {
      label: {
        type: String,
        default: ''
      },
      labelMultiple: {
        type: String,
        default: 'Selected'
      },
      options: {
        type: Array,
        default: () => []
      },
      selectedOptions: {
        type: Array,
        default: () => []
      },
      loading: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        selected: [],
        buttonLabel: '',
        selectionChanged: false
      }
    },
    computed: {
      parsedOptions () {
        return this.options.map((option) => {
          if (typeof option === 'string') {
            option = {
              name: option,
              slug: option
            }
          }
          return option
        })
      },
      transitionName () {
        return this.$device.isMobile ? 'dropdown-transition-mobile' : 'dropdown-transition'
      },
    },
    watch: {
      selectedOptions (val) {
        this.selected = Array.from(val)
        this.updateButtonLabel()
      }
    },
    created () {
      if (!this.selected.length) {
        this.selected = [...this.selectedOptions]
      }
      this.updateButtonLabel()
    },
    methods: {
      updateButtonLabel () {
        if (this.selected.length === 0) {
          this.buttonLabel = this.label
          return
        }
        this.buttonLabel = this.selected.length === 1 ? this.selected[0] : `${this.labelMultiple} • ${this.selected.length}`
      },
      showHandler () {
        this.selectionChanged = false
      },
      hideHandler () {
        if (this.selectionChanged) {
          this.updateButtonLabel()
          this.$emit('update', [...this.selected])
        }
      },
      checkboxHandler (option) {
        if (this.selected.includes(option)) {
          this.selected.splice(this.selected.indexOf(option), 1)
        } else {
          this.selected.push(option)
        }
        this.selectionChanged = true
      },
      update (slotProps, e) {
        slotProps.toggle(e)
      },
      clear () {
        this.selectionChanged = this.selected.length
        this.selected = []
      }
    }
  }
</script>

<style lang="scss">
.dropdown-filter {
  .overflow-container {
    max-height: 50vh;

    @screen md {
      max-height: 15rem;
    }
  }

  .mobile-header {
    @apply flex items-start justify-between items-center py-4 px-6 border-b border-gray-200;

    @screen md {
      @apply hidden;
    }

    .button-close {
      @apply flex rounded px-4 py-3 border-2 border-gray-200 outline-none;
    }
  }

  .button-toggle {
    @apply w-full;

    @screen md {
      @apply w-auto;
    }
  }

  .dropdown-container {
    @apply fixed bottom-0 left-0 top-auto w-full;

    @screen md {
      @apply absolute bottom-auto;
      top: 100%;
      width: 21.25rem;
    }
  }

  .dropdown-content {
    @apply rounded-none rounded-t;

    @screen md {
      @apply rounded;
    }
  }

  .dropdown-backdrop {
    @apply block;

    @screen md {
      @apply hidden;
    }
  }

  &.is-filled .button-toggle {
    box-shadow: inset 0 0 0 0.125rem theme("colors.black");
  }

  .button-clear {
    @apply font-primary font-bold text-blue-600;
    &:focus {
      @apply outline-none;
    }
    &:disabled {
      @apply text-gray-200;
    }
  }
}
</style>
