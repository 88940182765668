
<template>
  <div
    class="product-filters-holder"
    :style="getContainerCss"
  >
    <div
      class="product-filters left-0 bg-white z-20 w-full"
      :class="classes"
      :style="getOffsetCss"
    >
      <div class="container px-4 md:px-8">
        <div class="flex flex-wrap justify-between items-center gap-y-2 md:justify-start md:gap-y-0">
          <div class="w-full md:w-auto flex flex-col gap-y-2 md:flex-row md:gap-y-0 md:gap-x-4 xl:gap-x-6">
            <dropdown-filter
              v-if="!hasDefaultFilter(filterDefinitions.educationType)"
              label="Schoolniveau"
              label-multiple="Schoolniveau"
              :options="levels"
              :selected-options="selectedLevels"
              :loading="loadingLevels"
              class="filter-levels"
              @update="setLevels"
            />
            <dropdown-filter
              v-if="!hasDefaultFilter(filterDefinitions.subject)"
              label="Kies schoolvak"
              label-multiple="Schoolvak"
              :options="subjects.sort()"
              :selected-options="selectedSubjects"
              :loading="loadingSubjects"
              class="filter-subject"
              @update="setSubjects"
            />
            <dropdown-filter
              v-if="!hasDefaultFilter(filterDefinitions.brand)"
              label="Kies producttype"
              label-multiple="Producttype"
              :options="productTypes"
              :selected-options="selectedProductTypes"
              :loading="loadingProductTypes"
              class="filter-product-type"
              @update="setProductTypes"
            />
          </div>
          <div class="md:ml-auto">
            <DropdownOptions
              id="sorting"
              class="w-44"
              placeholder="Sorteer op"
              :options="sortOptions"
              :selected-option="sorting.selected"
              @onSelect="setSorting"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { computed } from '@nuxtjs/composition-api'
  import throttle from 'lodash.throttle'
  import DropdownFilter from '@/components/DropdownFilter'
  import DropdownOptions from '@/components/DropdownOptions'
  import { productFilters } from '@/constants/products'
  import { mediaQueries } from '@/constants/responsive'
  import { sortLevelsList } from '@/utils/utils'

  export default {
    components: {
      DropdownFilter,
      DropdownOptions
    },
    props: {
      availableFilters: {
        type: Array,
        default: () => []
      },
      defaultOptions: {
        type: Object,
        default: () => ({})
      },
      filters: {
        type: Object,
        default: () => ({})
      },
      sorting: {
        type: Object,
        default: () => ({})
      },
      currentPage: {
        type: Number,
        default: 1
      },
      loading: {
        type: Boolean,
        default: false
      },
      loadingFor: {
        type: String,
        default: ''
      }
    },

    setup (props, { emit }) {
      const setFilter = (filter) => {
        return (options) => {
          emit('filtered', {
            trigger: filter,
            value: {
              ...props.filters,
              [filter]: Array.isArray(options)
                ? options
                : [options]
            }
          })
        }
      }

      const getFilter = (filter) => {
        return computed(() => {
          return props.filters[filter] || []
        })
      }

      const getFilterOptions = (filter) => {
        return computed(() => {
          return props.availableFilters.find((availableFilter) => {
            return availableFilter.id === filter
          })?.options.map(item => item.id) || []
        })
      }

      const getLoadingStateForFilter = (filter) => {
        return computed(() => {
          return props.loading && props.loadingFor === filter
        })
      }

      const hasDefaultFilter = (attribute) => {
        if (!props.defaultOptions.filters) {
          return false
        }
        return !!props.defaultOptions.filters[attribute]?.length
      }

      const loadingSubjects = getLoadingStateForFilter(productFilters.subject)
      const selectedSubjects = getFilter(productFilters.subject)
      const subjects = getFilterOptions(productFilters.subject)
      const setSubjects = setFilter(productFilters.subject)

      const loadingLevels = getLoadingStateForFilter(productFilters.educationType)
      const selectedLevels = getFilter(productFilters.educationType)

      const levels = sortLevelsList(getFilterOptions(productFilters.educationType)?.value)
      const setLevels = setFilter(productFilters.educationType)

      const loadingProductTypes = getLoadingStateForFilter(productFilters.brand)
      const selectedProductTypes = getFilter(productFilters.brand)
      const productTypes = getFilterOptions(productFilters.brand)
      const setProductTypes = setFilter(productFilters.brand)

      const setSorting = (value) => {
        emit('sorted', value)
      }

      const sortOptions = computed(() => {
        return props.sorting.options.map((sort) => {
          return {
            id: sort.value,
            name: sort.label
          }
        })
      })

      return {
        hasDefaultFilter,
        setSorting,
        sortOptions,
        setLevels,
        setSubjects,
        setProductTypes,
        selectedLevels,
        selectedSubjects,
        selectedProductTypes,
        loadingLevels,
        loadingSubjects,
        loadingProductTypes,
        levels,
        subjects,
        productTypes
      }
    },
    data () {
      return {
        prevScroll: 0,
        fixedHeader: false,
        topOffset: 0,
        containerHeight: 0,
        scrollTrigger: 0,
        filterDefinitions: productFilters,
        hasSalesNotice: true
      }
    },
    computed: {
      ...mapState({
        modalName: state => state.modal.name
      }),
      classes () {
        return {
          'py-6': this.fixedHeader,
          'md:py-10': !this.fixedHeader,
          'is-fixed': this.fixedHeader
        }
      },
      getOffsetCss () {
        return 'top: ' + (this.fixedHeader ? this.topOffset : 0) + 'px'
      },
      getContainerCss () {
        return this.fixedHeader ? 'height: ' + this.containerHeight + 'px' : ''
      }
    },
    watch: {
      modalName (v) {
        const offset = window.pageYOffset
        if (v && !this.$device.isMobile && offset > this.scrollTrigger) {
          document.removeEventListener('scroll', this.handleScroll)
          this.fixedHeader = true
        } else {
          document.addEventListener('scroll', this.handleScroll)
        }
      }
    },
    mounted () {
      this.containerHeight = document.getElementsByClassName('product-filters')[0]?.offsetHeight
      this.scrollTrigger = document.getElementsByClassName('product-filters')[0]?.getBoundingClientRect().top - 88 + document.documentElement.scrollTop
      this.handleScroll()
      window.addEventListener('scroll', this.handleScroll)
      window.addEventListener('resize', () => this.calculateTopOffset())
      document.addEventListener('dismiss_sale_notice', this.updateSalesNotice)
      this.$nextTick(() => {
        this.calculateTopOffset()
      })
    },
    beforeDestroy () {
      window.removeEventListener('scroll', this.handleScroll)
      window.removeEventListener('resize', () => this.calculateTopOffset())
      document.removeEventListener('dismiss_sale_notice', this.updateSalesNotice)
    },
    methods: {
      updateSalesNotice () {
        this.hasSalesNotice = false
        this.calculateTopOffset(true)
      },
      calculateTopOffset (ignoreSalesNotice = false) {
        let offset = document.getElementsByClassName('header-content')[0]?.offsetHeight ?? 0
        if (!ignoreSalesNotice && this.hasSalesNotice) {
          offset += document.getElementsByClassName('sales-notice')[0]?.offsetHeight ?? 0
        }
        this.topOffset = offset
      },
      handleScroll: throttle(function () {
        if (this.menuOpen) {
          this.prevScroll = Math.abs(parseInt(document.body.style.top, 10)) + 1
          return
        }
        const offset = window.pageYOffset
        this.fixedHeader = window.matchMedia(mediaQueries.largerThanLg).matches && offset > this.scrollTrigger
      }, 60),
    }
  }
</script>

<style lang="scss">
.product-filters.is-fixed {
  @apply fixed;
  @apply py-3;
  box-shadow: 0 0.625rem 1.25rem rgba(0, 0, 0, 0.08);

  // we have a smaller vertical padding on the filters for screens of small height, and override it here
  @media screen and (min-height: 44em) {
    @apply py-6;
  }
}
.filter-subject {
  .button-toggle {
    min-width: theme('spacing.36');
  }
}
.filter-product-type {
  .button-toggle {
    min-width: theme('spacing.40');
  }
}
</style>
