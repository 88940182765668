<template>
  <div class="flex ml-auto">
    <div
      v-for="(star, index) in stars"
      :key="`star-${index}`"
      class="star-container"
    >
      <q-icon
        v-if="star"
        name="star"
        :class="{
          'ml-0': index === 0,
          'ml-1': index !== 0,
          'text-xl': size === 'xl',
          'text-sm': size === 'sm'
        }"
        :style="`color: ${fillColor};`"
      />
      <q-icon
        v-else
        name="star"
        :class="{
          'ml-0': index === 0,
          'ml-1': index !== 0,
          'text-xl': size === 'xl',
          'text-sm': size === 'sm'
        }"
        style="color: #CCCCCC;"
      />
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      starAmount: {
        type: Number,
        required: true
      },
      fillColor: {
        type: String,
        default: '#000000'
      },
      size: {
        type: String,
        default: 'xl'
      }
    },
    data () {
      return {
        stars: null
      }
    },
    mounted () {
      this.stars = this.setStars(this.starAmount)
    },
    methods: {
      setStars: (starAmount) => {
        return [1, 2, 3].map((n) => {
          if (n <= starAmount) {
            return true
          } else {
            return false
          }
        })
      }
    }
  }
</script>

<style>

</style>
