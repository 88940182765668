<template>
  <div>
    <h2 class="text-3xl text-center mb-10">
      {{ fields.title }}
    </h2>
    <ProductCarousel
      :default-options="defaultOptions"
    />
  </div>
</template>

<script>
  import ProductCarousel from '@/components/Ecommerce/Products/ProductCarousel'
  export default {
    components: { ProductCarousel },
    props: {
      fields: {
        type: Object,
        required: true
      },
      defaultOptions: {
        type: Object,
        required: true
      }
    }
  }
</script>
