// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/svg/call-to-action-pattern.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*purgecss start ignore*/\n.call-to-action{\n  position:relative;\n  display:flex;\n  height:100%;\n  flex-direction:column;\n  justify-content:center;\n  overflow:hidden;\n  border-radius:0.625rem;\n  padding-left:2.5rem;\n  padding-right:2.5rem;\n  text-align:center;\n  --tw-shadow:0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);\n  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);\n  background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  transition:box-shadow .2s cubic-bezier(.39,.575,.565,1)\n}\n.call-to-action:hover{\n  cursor:pointer;\n  --tw-shadow:0px 0px 0.0625rem rgba(0, 0, 0, 0.08), 0px 0.125rem 0.375rem rgba(0, 0, 0, 0.08), 0px 0.625rem 1.25rem rgba(0, 0, 0, 0.08);\n  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)\n}\n.call-to-action:hover .cta-button{\n  --tw-bg-opacity:1;\n  background-color:rgba(255, 255, 255, var(--tw-bg-opacity));\n  color:currentColor\n}\n.call-to-action h3+p{\n  margin-top:0.5rem;\n  font-weight:400\n}\n.call-to-action .cta-button{\n  display:inline-block;\n  border-radius:0.625rem;\n  padding-left:1rem;\n  padding-right:1rem;\n  padding-top:0.75rem;\n  padding-bottom:0.75rem;\n  font-weight:700;\n  --tw-text-opacity:1;\n  color:rgba(255, 255, 255, var(--tw-text-opacity));\n  box-shadow:inset 0 0 0 .125rem hsla(0,0%,100%,.8);\n  transition:all .25s cubic-bezier(.39,.575,.565,1)\n}\n@media (min-width: 48em){\n.call-to-action.large{\n    padding-top:4.5rem;\n    padding-bottom:4.5rem\n}\n.call-to-action.large h3{\n    font-size:3rem\n}\n.call-to-action.large p{\n    margin-left:auto;\n    margin-right:auto;\n    max-width:20rem\n}\n}\n\n/*purgecss end ignore*/", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
