var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex ml-auto"},_vm._l((_vm.stars),function(star,index){return _c('div',{key:("star-" + index),staticClass:"star-container"},[(star)?_c('q-icon',{class:{
        'ml-0': index === 0,
        'ml-1': index !== 0,
        'text-xl': _vm.size === 'xl',
        'text-sm': _vm.size === 'sm'
      },style:(("color: " + _vm.fillColor + ";")),attrs:{"name":"star"}}):_c('q-icon',{class:{
        'ml-0': index === 0,
        'ml-1': index !== 0,
        'text-xl': _vm.size === 'xl',
        'text-sm': _vm.size === 'sm'
      },staticStyle:{"color":"#CCCCCC"},attrs:{"name":"star"}})],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }