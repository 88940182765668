<template>
  <div
    v-if="hasProducts"
    class="md:px-8"
  >
    <div class="w-full relative product-slider-wrapper">
      <CarouselControls
        class="hidden md:flex pointer-events-none z-30 justify-between absolute left-0 right-0 top-center transform translate-y-center px-4"
        :has-prev="slide > 0"
        :has-next="hasNext"
        @next="next()"
        @prev="prev()"
      />
      <CarouselBase
        class="pb-1 pt-1 product-slider"
        :active-slide="slide"
        :items="products"
        show-progress
        @slide-change="slide = $event"
        @has-next="hasNext = $event"
      >
        <div
          v-for="(product, index) in products"
          :key="product.productId || index"
          class="slide"
        >
          <transition
            name="fade"
            mode="out-in"
          >
            <ProductCard
              v-if="isNaN(product)"
              :product="product"
            />
            <ProductCardSkeleton v-else />
          </transition>
        </div>
      </CarouselBase>
    </div>
  </div>
</template>

<script>
  import { computed, ref } from '@nuxtjs/composition-api'
  import { facetGetters, useFacet } from '@propeller-commerce/propeller'
  import { onSSR } from '@vue-storefront/core'
  import merge from 'lodash.merge'

  import ProductCard from '@/components/Ecommerce/Products/ProductCard.vue'
  import CarouselBase from '@/components/CarouselBase'
  import CarouselControls from '@/components/CarouselControls'
  import ProductCardSkeleton from '@/components/Ecommerce/Products/ProductCardSkeleton.vue'

  import { useCarousel } from '@/composables/useCarousel'

  export default {
    components: {
      ProductCard,
      CarouselBase,
      CarouselControls,
      ProductCardSkeleton
    },
    props: {
      defaultOptions: {
        type: Object,
        required: true
      }
    },
    setup (props) {
      const {
        hasNext,
        hasPrev,
        next,
        prev,
        slide
      } = useCarousel()

      const {
        result,
        search,
        loading
      } = useFacet(JSON.stringify(props.defaultOptions))

      const query = ref(merge({
        categorySlug: 'examenbundel'
      }, props.defaultOptions))

      onSSR(async () => {
        await search(query.value)
      })

      const hasProducts = computed(() => result.value?.data?.items && result.value?.data?.items.length)

      const products = computed(() => {
        return hasProducts.value
          ? facetGetters.getProducts(result.value)
          : Array.from(Array(12).keys())
      })

      const sliderIsNotNeededOnDesktop = computed(() => {
        return products.value.length <= 4
      })

      return {
        products,
        hasProducts,
        sliderIsNotNeededOnDesktop,
        hasNext,
        loading,
        slide,
        next,
        prev,
        hasPrev
      }
    }
  }
</script>

<style lang="scss">
.product-slider .carousel-slides-wrapper > * {
  width: 18.4375rem;

  &:last-child {
    margin-right: 0;
  }
}

.product-slider-wrapper {
  .carousel-controls {
    button {
      @apply pointer-events-auto;
      @apply bg-white;

      &:first-child {
        transform: translateX(-2rem);
      }
      &:last-child {
        transform: translateX(2rem);
      }
    }
  }
}
</style>
