import { render, staticRenderFns } from "./Skeleton.vue?vue&type=template&id=6b4261dc&scoped=true&"
var script = {}
import style0 from "./Skeleton.vue?vue&type=style&index=0&id=6b4261dc&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b4261dc",
  null
  
)

export default component.exports