<template>
  <article class="view view-home md:pt-10">
    <header class="view-header md:pb-10">
      <div class="relative container px-4 md:px-8">
        <client-only>
          <homepage-books v-if="!$device.isMobile" />
        </client-only>
        <div class="w-full md:w-10/12 xl:w-6/12 mx-auto">
          <animated-title>
            <h1 class="animate text-3xl md:text-5xl pb-4 md:text-center">
              {{ page.fields.title }}
            </h1>
          </animated-title>
          <rich-text-content
            :content="page.fields.introduction"
            class="text-lg md:text-center"
          />
          <div class="md:text-center mt-10">
            <e-button
              tag="nuxt-link"
              to="/producten"
              @click.native="trackEvent()"
            >
              Bekijk examenbundels
            </e-button>
          </div>
        </div>
      </div>
    </header>
    <flexible-content :content="page.fields.content" />
    <articles-carousel />
  </article>
</template>

<script>

  import HomepageBooks from '@/components/HomepageBooks'
  import FlexibleContent from '@/components/FlexibleContent'
  import ArticlesCarousel from '@/components/ArticlesCarousel'
  import { HeadMeta } from '@/utils/head-meta'
  import { gtmSendEvent } from '@/utils/gtm-send-event'

  export default {
    components: {
      HomepageBooks,
      FlexibleContent,
      ArticlesCarousel
    },
    async asyncData ({ app }) {
      const { items } = await app.$contentful.getEntries({
        content_type: 'page',
        'fields.template': 'Homepage',
        include: 2
      })
      return {
        page: items[0]
      }
    },
    head () {
      return HeadMeta(this.page.fields)
    },
    methods: {
      trackEvent () {
        gtmSendEvent('home', 'products-cta', 'clicked')
      }
    }
  }
</script>

<style lang="scss">
.view-home {
  .rich-text {
    &:first-of-type {
      @apply text-lg;

      @screen md {
        @apply pb-10;
      }
    }
  }

  .articles-carousel {
    @apply mt-4;
    @screen md {
      @apply mt-40;
    }
  }
}
</style>
