<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <component
      :is="interactive ? 'nuxt-link' : 'div'"
      :to="interactive ? `/producten/voordeelpakketten/${comboId}/${slug}` : ''"
    >
      <card-base
        class="card-product w-full"
        :interactive="!minimal"
        :class="{
          'is-minimal': minimal,
          'is-disabled': disabled,
          'is-active': active
        }"
      >
        <header>
          <h3 class="product-title">
            {{ name }}
          </h3>
          <p class="product-subtitle">
            {{ productType }}
          </p>
          <p class="text-gray-600 product-category">
            {{ levels }}
          </p>
        </header>
        <figure class="product-image mb-6 mt-4 pointer-events-none flex-shrink-0">
          <img
            :src="findImage(comboId, contentfulImages)"
            :alt="name"
            class="mx-auto"
          >
        </figure>

        <footer v-if="!minimal">
          <div class="flex justify-between items-end">
            <div class="flex flex-col items-start space-y-2">
              <ProductPrice
                :price="originalPrice"
                :discount="discountPrice"
                direction="col"
              />
              <Badge primary>
                {{ discountAmount }} euro korting
              </Badge>
            </div>
            <div>
              <ProductAddToCart
                class="w-12 h-12"
                rounded
                :disabled="!allItemsOrderable"
                :product="{...bundle, bundleId: comboId}"
              />
            </div>
          </div>
        </footer>
      </card-base>
    </component>
  </transition>
</template>

<script>
  import { productGetters } from '@propeller-commerce/propeller'
  import { computed } from '@nuxtjs/composition-api'

  import { useListAttribute } from '@/composables/useAttribute'
  import Badge from '@/components/Ecommerce/Badge'
  import CardBase from '@/components/CardBase'
  import ProductPrice from '@/components/Ecommerce/Products/ProductPrice'
  import ProductAddToCart from '@/components/Ecommerce/Products/ProductAddToCart'
  import { allBundleItemsOrderable, getBundleProductType } from '@/utils/utils'
  import { productAttributes } from '@/constants/products.module'

  export default {
    components: {
      Badge,
      CardBase,
      ProductPrice,
      ProductAddToCart
    },
    props: {
      product: {
        type: Object,
        default: null
      },
      minimal: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      active: {
        type: Boolean,
        default: false
      },
      interactive: {
        type: Boolean,
        default: true
      },
      contentfulImages: {
        type: Array,
        default: () => []
      }
    },
    setup (props) {
      const bundle = computed(() => props.product.bundles?.[0])
      const comboId = computed(() => props.product.bundles?.[0]?.id)
      const findImage = (bundleId, bundleImages) => {
        const foundImage = bundleImages?.find(image => image.propellerId === bundleId)
        if (foundImage?.file) {
          return foundImage.file.url
        }
        return require('~/assets/img/no-image-bundle.png')
      }
      return {
        name: computed(() => bundle?.value.name),
        comboId,
        discountPrice: computed(() => bundle?.value.price?.net),
        originalPrice: computed(() => bundle?.value.price?.originalNet),
        slug: computed(() => productGetters.getSlug(props.product)),
        productType: computed(() => getBundleProductType(bundle?.value.items)),
        levels: computed(() => useListAttribute(bundle?.value.items[0].product, productAttributes.educationType).value.join(', ')),
        allItemsOrderable: computed(() => allBundleItemsOrderable(bundle?.value.items)),
        bundle,
        findImage
      }
    },
    computed: {
      discountAmount () {
        return (this.originalPrice - this.discountPrice).toFixed(2)
      }
    }
  }
</script>

<style lang="scss" scoped>

.card-product {
  @apply h-full p-6 text-center;

  .product-title {
    @apply font-primary text-lg leading-normal;
  }

  .product-category,
  .product-subtitle {
    @apply text-gray-600;
  }

  &.is-minimal {
    @apply p-3;

    header {
      width: 73%;
      @screen md {
        @apply w-full;
      }
    }

    .product-category,
    .product-subtitle {
      @apply text-xs;
    }

    figure {
      @apply mb-0;
    }

    @apply flex flex-row-reverse justify-end items-center;
    .product-image {
      @apply mt-0;
      @apply w-20;
    }

    .product-title,
    .product-category,
    .product-subtitle {
      @apply text-left;
    }

    @screen sm {
      @apply p-6;
      @apply block;
      .product-image {
        @apply mt-6;
        @apply w-auto;
      }
      .product-title,
      .product-category,
      .product-subtitle {
        @apply text-center;
      }
    }
  }

  &.is-active {
    @apply shadow-big;
  }

  &.is-disabled {
    @apply shadow-none;
    @apply border border-gray-100;
  }
}

</style>
