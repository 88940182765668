<template>
  <div
    v-if="articles.length"
    class="articles-carousel container px-4 md:px-8"
  >
    <div class="flex flex-wrap -mx-4">
      <div class="w-full md:w-3/12 px-4">
        <h2 class="text-3xl pb-10">
          Laatste nieuws
        </h2>
        <carousel-controls
          :has-prev="slide > 0"
          :has-next="hasNext"
          class="hidden md:block"
          @next="next()"
          @prev="prev()"
        />
      </div>
      <div class="w-full md:w-9/12">
        <carousel-base
          :active-slide="slide"
          @slide-change="slide = $event"
          @has-next="hasNext = $event"
        >
          <div
            v-for="(article, i) in articles"
            :key="i"
            class="slide"
          >
            <card-article :post="article" />
          </div>
        </carousel-base>
      </div>
    </div>
  </div>
</template>

<script>

  import CarouselControls from '@/components/CarouselControls'
  import CarouselBase from '@/components/CarouselBase'
  import CardArticle from '@/components/CardArticle'
  import { gtmSendEvent } from '@/utils/gtm-send-event'

  export default {
    components: {
      CarouselControls,
      CarouselBase,
      CardArticle
    },
    data () {
      return {
        articles: [],
        slide: 0,
        hasNext: true
      }
    },
    async fetch () {
      const { items } = await this.$nuxt.context.$contentful.getEntries({
        content_type: 'article',
        order: '-fields.publishDate',
        'fields.category.sys.contentType.sys.id': 'category',
        'fields.category.fields.slug[in]': 'nieuws',
        limit: 6
      })
      this.articles = items
    },
    methods: {
      next () {
        is.slide = this.slide + 1
        gtmSendEvent('home', 'articles-carousel', 'next')
      },
      prev () {
        is.slide = this.slide - 1
        gtmSendEvent('home', 'articles-carousel', 'prev')
      }
    }
  }
</script>

<style lang="scss">
.articles-carousel {
  .slide {
    @apply mr-8 pb-6;
    width: 17.5rem;
  }
}
</style>
