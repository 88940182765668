
export const useView = () => {
    const isInViewport = (element) => {
        const rect = element.getBoundingClientRect()
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        )
    }

    const isBehindOtherElement = (element) => {
        const boundingRect = element.getBoundingClientRect()
        // adjust coordinates to get more accurate results
        const left = boundingRect.left + 1
        const right = boundingRect.right - 1
        const top = boundingRect.top + 1
        const bottom = boundingRect.bottom - 1

        if (document.elementFromPoint(left, top) !== element) {
            return true
        }
        if (document.elementFromPoint(right, top) !== element) {
            return true
        }
        if (document.elementFromPoint(left, bottom) !== element) {
            return true
        }
        if (document.elementFromPoint(right, bottom) !== element) {
            return true
        }

        return false
    }

    const scrollToElement = (element, offset = 0) => {
        const y = element.getBoundingClientRect().top + (window.pageYOffset - offset)
        window.scrollTo({ top: y, behavior: 'smooth' })
    }

    return {
        isInViewport,
        scrollToElement,
        isBehindOtherElement
    }
}
