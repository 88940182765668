import { computed, ref } from '@nuxtjs/composition-api'

export const useCarousel = (defaultSlide = 0) => {
    const hasNext = ref(true)
    const slide = ref(defaultSlide)

    const hasPrev = computed(() => {
        return slide.value > 0
    })

    const next = () => {
        slide.value++
    }

    const prev = () => {
        slide.value--
    }

    return {
        hasNext,
        hasPrev,
        slide,
        next,
        prev
    }
}
