<template>
  <div class="carousel-controls flex">
    <e-button
      type="secondary"
      class="mr-4"
      :disabled="!hasPrev"
      aria-label="Previous slide"
      @click="clickHandler('prev')"
    >
      <q-icon name="arrow-left" />
    </e-button>
    <e-button
      type="secondary"
      :disabled="!hasNext"
      aria-label="Next slide"
      @click="clickHandler('next')"
    >
      <q-icon name="arrow-right" />
    </e-button>
  </div>
</template>

<script>
  export default {
    props: {
      hasNext: {
        type: Boolean,
        default: true
      },
      hasPrev: {
        type: Boolean,
        default: true
      }
    },
    methods: {
      clickHandler (direction) {
        this.$emit(direction)
      }
    }
  }
</script>
