import { useRoute } from '@nuxtjs/composition-api';

const nonFilters = new Set(['offset', 'page', 'sort', 'term']);

const reduceFilters = (query) => (prev, curr) => {
    const makeArray = Array.isArray(query[curr]) || nonFilters.has(curr);

    return {
        ...prev,
        [curr]: makeArray ? query[curr] : [query[curr]],
    };
};

const useUiHelpers = () => {
    const route = useRoute();
    const { query } = route.value;

    const getFiltersDataFromUrl = (onlyFilters) =>
        Object.keys(query)
            .filter((f) => (onlyFilters ? !nonFilters.has(f) : nonFilters.has(f)))
            // eslint-disable-next-line unicorn/prefer-object-from-entries
            .reduce(reduceFilters(query), {});

    const getFacetsFromURL = (filters = true) => ({
            filters: filters ? getFiltersDataFromUrl(true) : {},
            categorySlug: route.value.params.slug_1,
            offset: Number.parseInt(query.offset as string, 10) || 12,
            page: Number.parseInt(query.page as string, 10) || 1,
            sort: (query.sort as string) || '',
            term: query.term as string,
    });

    return {
        getFacetsFromURL,
        getFiltersDataFromUrl,
        reduceFilters
    };
};

export default useUiHelpers;
