<template>
  <div class="dropdown-options">
    <label
      v-if="label"
      :for="id"
      class="label"
    >
      {{ label }}
    </label>
    <dropdown-base>
      <template #button-content="slotProps">
        <button
          class="dropdown-button button button-secondary"
          @click="slotProps.toggle"
        >
          <div class="w-full flex justify-between">
            <span class="block truncate">
              {{ selectedName || placeholder }}
            </span>
            <q-icon
              name="chevron-down"
              class="default-transition"
            />
          </div>
        </button>
      </template>
      <template #default="slotProps">
        <div class="py-3 px-6">
          <ul>
            <li
              v-for="item in options"
              :key="`option-${item.id}`"
              class="py-3"
            >
              <button
                class="dropdown-list-button block font-bold w-full text-left focus:outline-none hover:text-blue-500 default-transition"
                @click.prevent="slotProps.toggle($event), handleUpdate(item.id)"
              >
                {{ item.name }}
              </button>
            </li>
          </ul>
        </div>
      </template>
    </dropdown-base>
  </div>
</template>

<script>

  import DropdownBase from '@/components/DropdownBase'

  export default {
    components: {
      DropdownBase
    },
    props: {
      id: {
        type: [String, Number],
        default: ''
      },
      selectedOption: {
        type: [String, Number],
        default: ''
      },
      label: {
        type: String,
        default: ''
      },
      placeholder: {
        type: String,
        default: 'Maak een keuze'
      },
      options: {
        type: Array,
        default: () => []
      }
    },
    data () {
      return {
        internalValue: false
      }
    },
    computed: {
      selectedName () {
        const selected = this.selectedOption || this.internalValue
        const option = this.options.find(option => option.id === selected)
        if (!option) return false
        return option.name
      }
    },
    watch: {
      selectedOption (val) {
        if (!val) this.internalValue = false
      }
    },
    methods: {
      handleUpdate (id) {
        this.internalValue = id
        this.$emit('onSelect', id)
      }
    }
  }

</script>

<style lang="scss">

  .dropdown-options {
    @apply flex flex-col pb-3 md:pb-0;

    &.is-expanded {
      .icon-chevron-down {
        transform: rotate(180deg);
      }
    }

    .label {
      @apply text-base pb-1 inline-block;
    }

    .button {
      @apply w-full px-3 rounded-sm font-normal;
    }

    .dropdown-container {
      @apply w-full;
    }

  }

</style>
