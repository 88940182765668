<template>
  <div class="product-filters-holder py-10">
    <div class="product-filters left-0 bg-white z-20 w-full">
      <div class="container px-4 md:px-8">
        <div class="flex flex-wrap justify-between items-center">
          <div class="flex flex-wrap items-center w-full md:w-auto">
            <div class="w-full md:w-auto flex space-x-4 md:space-x-6">
              <Skeleton class="h-10 w-full md:w-36 rounded-full" />
              <Skeleton class="h-10 w-full md:w-40 rounded-full" />
              <Skeleton class="h-10 w-full md:w-40 rounded-full" />
            </div>
          </div>
          <div class="w-full md:w-auto mt-5 md:mt-0">
            <Skeleton class="h-8 w-full md:w-40" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Skeleton from '@/components/Skeleton'
  import { productFilters } from '@/constants/products'

  export default {
    components: {
      Skeleton
    },
    props: {
      defaultOptions: {
        type: Object,
        default: () => ({})
      }
    },
    setup (props) {
      const hasDefaultFilter = (attribute) => {
        if (!props.defaultOptions.filters) {
          return false
        }
        return !!props.defaultOptions.filters[attribute]
      }

      return {
        hasDefaultFilter,
        filterDefinitions: productFilters
      }
    }
  }
</script>
