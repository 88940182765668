<template>
  <div class="call-to-action-section container px-4 md:px-8 my-16 md:my-32">
    <div class="w-full lg:w-10/12 mx-auto">
      <div class="flex flex-col-reverse md:flex-row flex-wrap items-center -mx-4">
        <div class="w-full md:w-1/2 px-4">
          <div class="w-full lg:w-4/5">
            <h2
              v-if="fields.contentTitle"
              class="text-2xl lg:text-3xl mb-4"
            >
              {{ fields.contentTitle }}
            </h2>
            <p
              v-if="fields.contentText"
              class="mb-6"
            >
              {{ fields.contentText }}
            </p>
            <e-button
              :tag="tag"
              v-bind="attrs"
              class="mb-6 md:mb-0"
              @click.native="trackEvent(fields.buttonText)"
            >
              {{ fields.buttonText }}
            </e-button>
          </div>
        </div>
        <div class="w-full md:w-1/2 px-4 pb-8 md:pb-0">
          <div class="media-container bg-white rounded shadow overflow-hidden">
            <video
              v-if="video"
              muted
              playsInline
              autoPlay
              loop
            >
              <source
                :src="videoUrl"
                :type="video.contentType"
              >
            </video>
            <img
              v-else
              :src="imageUrl"
              :alt="imageFields.title"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import isRelativeUrl from '@/mixins/isRelativeUrl';
  import { gtmSendEvent } from '@/utils/gtm-send-event';

  export default {
    mixins: [isRelativeUrl],
    props: {
      data: {
        type: Object,
        default: () => {},
        required: true
      }
    },
    computed: {
      fields () {
        return this.data.fields
      },
      tag () {
        return this.isRelativeUrl(this.fields.buttonLink) ? 'nuxt-link' : 'a'
      },
      attrs () {
        let attrs = {}
        if (this.isRelativeUrl(this.fields.buttonLink)) {
          attrs = { to: this.fields.buttonLink }
        } else {
          attrs = {
            href: this.fields.buttonLink,
            target: '_blank'
          }
        }
        return attrs
      },
      imageFields () {
        return this.fields.image?.fields || {}
      },
      imageUrl () {
        return this.imageFields.length > 0 ? `https:${this.imageFields.file.url}?w=640&h=360&fm=jpg&fit=fill&fl=progressive&q=80` : ''
      },
      video () {
        if (!this.fields.video) return false
        return this.fields.video.fields.file
      },
      videoUrl () {
        return `https:${this.video.url}`
      }
    },
    methods: {
      trackEvent (buttonText) {
        gtmSendEvent('call-to-action-section', 'button-click', buttonText)
      }
    }
  }
</script>
