<template>
  <component
    :is="tag"
    v-bind="attrs"
    :class="classes"
    class="call-to-action py-9"
    @click.native="trackEvent(fields)"
  >
    <div class="call-to-action-content relative">
      <rating-stars
        v-if="fields.icon"
        class="text-white mb-4"
      />
      <h2
        v-if="fields.contentTitle"
        class="text-white text-2xl"
      >
        {{ fields.contentTitle }}
      </h2>
      <p
        v-if="fields.contentText"
        class="text-white font-bold"
      >
        {{ fields.contentText }}
      </p>
      <button class="cta-button mt-4">
        {{ fields.buttonText }}
      </button>
    </div>
  </component>
</template>

<script>

  import RatingStars from '@/components/RatingStars'
  import isRelativeUrl from '@/mixins/isRelativeUrl'
  import { gtmSendEvent } from '@/utils/gtm-send-event'

  export default {
    components: {
      RatingStars
    },
    mixins: [isRelativeUrl],
    props: {
      fields: {
        type: Object,
        default: () => {},
        required: true
      },
      size: {
        type: String,
        default: ''
      },
      useUserColor: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        hover: false
      }
    },
    computed: {
      tag () {
        return this.isRelativeUrl(this.fields.buttonLink) ? 'nuxt-link' : 'a'
      },
      attrs () {
        let attrs = {}
        if (this.isRelativeUrl(this.fields.buttonLink)) {
          attrs = { to: this.fields.buttonLink }
        } else {
          attrs = {
            href: this.fields.buttonLink,
            target: '_blank'
          }
        }
        return attrs
      },
      classes () {
        let bg = 'bg-red-500'
        let text = 'text-red-500'
        if (this.fields.color) {
          bg = `bg-${this.fields.color.toLowerCase()}-500`
          text = `text-${this.fields.color.toLowerCase()}-500`
        }
        if (this.useUserColor) {
          bg = 'user-level-bg-light'
          text = 'user-level-text-light'
        }
        return [bg, text, this.size]
      }
    },
    methods: {
      trackEvent (fields) {
        gtmSendEvent('call-to-action', 'button-click', fields.buttonLink)
      }
    }
  }
</script>

<style lang="scss">
.call-to-action {
  @apply relative overflow-hidden h-full flex flex-col justify-center rounded shadow text-center px-10;
  background-image: url("~assets/svg/call-to-action-pattern.svg");
  transition: box-shadow 0.2s ease(easeOutSine);

  &:hover {
    @apply shadow-focus cursor-pointer;

    .cta-button {
      @apply bg-white;
      color: currentColor;
    }
  }

  h3 + p {
    @apply font-normal mt-2;
  }

  .cta-button {
    @apply rounded inline-block font-bold px-4 py-3 text-white;
    box-shadow: inset 0 0 0 0.125rem rgba(255, 255, 255, 0.8);
    transition: all 0.25s ease(easeOutSine);
  }

  &.large {
    @screen md {
      @apply py-18;
      h3 {
        @apply text-3xl;
      }
      p {
        @apply mx-auto;
        max-width: 20rem;
      }
    }
  }
}
</style>
