<template>
  <div class="carousel-base px-4">
    <div
      ref="carousel"
      class="carousel-slides-wrapper"
    >
      <slot />
    </div>
  </div>
</template>

<script>

  import { gsap } from 'gsap'
  import { Draggable } from 'gsap/Draggable'
  import { InertiaPlugin } from 'gsap/InertiaPlugin'
  import debounce from 'lodash.debounce'

  export default {
    props: {
      activeSlide: {
        type: Number,
        default: 0
      },
      spaceBetween: {
        type: Number,
        default: 32
      },
      items: {
        type: Array,
        default: () => ([])
      },
      showProgress: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        draggable: null,
        slideWidth: 0,
        slidesCount: 0,
        totalSlidesWidth: 0,
        innerValue: 0,
        isDragging: false,
        debouncedHandler: null
      }
    },
    computed: {
      active: {
        get () {
          return this.activeSlide || this.innerValue
        },
        set (val) {
          this.innerValue = val
        }
      },
      hasNext () {
        if (this.slideWidth > 0) {
          return -this.active * (this.slideWidth + this.spaceBetween) > this.dragBounds().minX
        } else {
          return true
        }
      },
      maxActive () {
        return Math.round(Math.abs(this.dragBounds().minX / this.slideWidth))
      }
    },
    watch: {
      items (val) {
        this.$nextTick(() => {
          this.setup()
        })
      },
      activeSlide (newVal, oldVal) {
        this.active = newVal
        if (!this.isDragging) {
          this.animateToSlide()
        }
      },
      hasNext (val) {
        this.$emit('has-next', val)
      }
    },
    mounted () {
      gsap.registerPlugin(Draggable, InertiaPlugin)
      this.setup()
    },
    beforeDestroy () {
      if (this.draggable) {
        this.draggable.kill()
      }
      window.removeEventListener('resize', this.debouncedHandler)
    },
    methods: {
      setup () {
        const slides = this.$el.querySelectorAll('.slide')
        slides.forEach((slide) => { slide.style.marginRight = this.spaceBetween + 'px' })
        this.slideWidth = slides[0].clientWidth
        this.slidesCount = slides.length
        this.totalSlidesWidth = (this.slidesCount * this.slideWidth) + (this.slidesCount * this.spaceBetween)
        this.$refs.carousel.style.width = this.totalSlidesWidth + 'px'
        this.createDraggable()
        this.debouncedHandler = debounce(this.handleResize, 500)
        window.addEventListener('resize', this.debouncedHandler)
      },

      handleResize () {
        this.draggable.applyBounds(this.dragBounds())
      },
      // internal use only
      prevSlide () {
        if (this.active === 0 || this.isDragging) return
        this.innerValue = this.innerValue - 1
        this.$emit('slide-change', this.innerValue)
        this.animateToSlide()
      },
      // internal use only
      nextSlide () {
        if (!this.hasNext || this.isDragging) return
        this.innerValue = this.innerValue + 1
        this.$emit('slide-change', this.innerValue)
        this.animateToSlide()
      },
      animateToSlide () {
        let distance = -this.active * (this.slideWidth + this.spaceBetween)
        distance = distance < this.dragBounds().minX ? this.dragBounds().minX : distance
        gsap.to(this.$refs.carousel, {
          x: distance,
          duration: 0.4,
          ease: 'back.out(1.1)'
        })
      },
      dragBounds () {
        let minX = -(this.totalSlidesWidth + (this.slidesCount - 1) - this.$el.clientWidth)
        minX = this.totalSlidesWidth < this.$el.clientWidth ? 0 : minX
        return {
          minX,
          maxX: 0
        }
      },
      handleDragSnap (endValue) {
        const endIndex = endValue > 0 ? 0 : Math.round(Math.abs(endValue) / this.slideWidth)
        this.innerValue = endIndex > this.maxActive ? this.maxActive : endIndex
        this.$emit('slide-change', this.innerValue)
        return -(this.innerValue * (this.slideWidth + this.spaceBetween))
      },
      createDraggable () {
        this.draggable = Draggable.create(this.$refs.carousel, {
          type: 'x',
          bounds: this.dragBounds(),
          inertia: true,
          edgeResistance: 0.95,
          snap: {
            x: endValue => this.handleDragSnap(endValue)
          },
          onDragStart: () => { this.isDragging = true },
          onClick: () => { this.isDragging = false },
          onThrowComplete: () => { this.isDragging = false }
        })[0]
      }
    }
  }
</script>

<style lang="scss">

  .carousel-base {
    @apply relative overflow-hidden z-10;

    .carousel-slides-wrapper {
      @apply inline-block whitespace-nowrap;
      font-size: 0;

      > .slide {
        @apply inline-block whitespace-normal;
      }

    }

  }

</style>
