<template>
  <div
    v-if="totalPages > 1"
    class="ais-Pagination pagination-product-grid"
  >
    <transition-group
      name="list-transition"
      tag="ul"
      class="ais-Pagination-list"
    >
      <li
        key="previous"
        class="ais-Pagination-item ais-Pagination-item--previousPage"
        :class="{
          'ais-Pagination-item--disabled': previousDisabled
        }"
        @click="navigate(currentPage - 1)"
      >
        <span
          aria-label="Previous"
          class="ais-Pagination-link"
        >
          ‹
        </span>
      </li>
      <li
        v-for="(item, key) in items"
        :key="item"
        class="ais-Pagination-item"
        :class="{
          'hidden md:block': [0, 4].includes(key),
          'ais-Pagination-item--selected': isCurrentPage(item)
        }"
        @click="navigate(item)"
      >
        <a class="ais-Pagination-link relative">
          <transition
            name="fade"
            mode="out-in"
          >
            <span
              v-if="loading && loadingFor === 'PAGINATION' && isCurrentPage(item)"
              class="pagination-loader"
            >
              <q-loader class="is-black is-small" />
            </span>
            <span v-else>
              {{ item }}
            </span>
          </transition>
        </a>
      </li>
      <li
        key="next"
        class="ais-Pagination-item ais-Pagination-item--nextPage"
        :class="{
          'ais-Pagination-item--disabled': nextDisabled
        }"
        @click="navigate(currentPage + 1)"
      >
        <span
          aria-label="Next"
          class="ais-Pagination-link"
        >
          ›
        </span>
      </li>
    </transition-group>
  </div>
</template>

<script>
  import { computed } from '@nuxtjs/composition-api'
  import { QLoader } from '@qikkeronline/qikker-ui'

  export default {
    components: {
      QLoader
    },
    props: {
      pagination: {
        type: Object,
        required: true
      },
      padding: {
        type: Number,
        default: 2
      },
      loading: {
        type: Boolean,
        default: false
      },
      loadingFor: {
        type: String,
        default: ''
      }
    },
    setup (props, { emit }) {
      const currentPage = computed(() => props.pagination.currentPage)
      const totalPages = computed(() => props.pagination.totalPages)

      const previousDisabled = computed(() => props.pagination.currentPage === 1)
      const nextDisabled = computed(() => props.pagination.currentPage === props.pagination.totalPages)

      const totalItemsToShow = computed(() => {
        return 1 + (props.padding * 2)
      })

      const items = computed(() => {
        const items = Array.from({ length: totalPages.value }, (_, i) => i + 1)

        // Get first 5 items
        if (currentPage.value <= props.padding) {
          return items.slice(0, totalItemsToShow.value)
        }

        // Get last 5 items
        if (currentPage.value >= (totalPages.value - props.padding)) {
          return items.slice(-totalItemsToShow.value)
        }

        return items.slice((currentPage.value - 1) - props.padding, currentPage.value + props.padding)
      })

      const isCurrentPage = (num) => {
        return num === currentPage.value
      }

      const navigate = (num) => {
        emit('paginated', num)
      }

      return {
        navigate,
        isCurrentPage,
        currentPage,
        totalPages,
        previousDisabled,
        nextDisabled,
        items
      }
    }
  }
</script>

<style scoped lang="scss">
.pagination-product-grid {
  .pagination-loader {
    @apply flex;
    @apply items-center;
    .is-small {
      width: 1.375rem;
      height: 1.375rem;
    }
  }
  .ais-Pagination-link {
    @apply w-12;
    @apply h-12;
    @apply block;
    @apply flex;
    @apply justify-center;
    @apply items-center;
    @apply p-0;
  }
}
</style>
