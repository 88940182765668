<template>
  <card-base class="card-featured">
    <component
      :is="linkTag"
      :to="to"
      :href="link"
      :target="target"
    >
      <card-image
        :image="image"
        :alt="alt"
      />
      <div class="card-content">
        <h3
          class="font-primary text-base leading-normal"
          :title="title"
        >
          {{ title }}
        </h3>
      </div>
    </component>
  </card-base>
</template>

<script>
  import CardBase from '@/components/CardBase'
  import CardImage from '@/components/CardImage'
  import isRelativeUrl from '@/mixins/isRelativeUrl'

  export default {
    components: {
      CardBase,
      CardImage
    },
    mixins: [isRelativeUrl],
    props: {
      image: {
        type: String,
        default: ''
      },
      alt: {
        type: String,
        default: ''
      },
      title: {
        type: String,
        default: ''
      },
      link: {
        type: String,
        default: ''
      }
    },
    computed: {
      linkTag () {
        return this.isRelativeUrl(this.link) ? 'nuxt-link' : 'a'
      },
      target () {
        return this.linkTag === 'a' ? '_blank' : null
      },
      to () {
        return this.linkTag === 'nuxt-link' ? this.link : null
      }
    }
  }
</script>

<style lang="scss">

  .card-featured {

    .card-content {
      @apply flex flex-col justify-center bg-white p-6 h-24;

      h3 {
        @apply overflow-hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

    }

  }

</style>
