<template>
  <div class="cards-grid container px-4 md:px-8">
    <h2
      v-if="$route.path === '/' || $route.path === '/over-examenbundel'"
      class="text-3xl md:text-center md:pb-10"
    >
      Ontdek meer
    </h2>
    <div
      class="flex flex-wrap w-full xl:w-10/12 mx-auto pt-10 md:-mb-10"
      :class="{ 'justify-center': data.length < 3 }"
    >
      <div
        v-for="(component, i) in data"
        :key="i"
        class="w-full md:w-1/2 lg:w-1/3 pb-6 md:px-5 md:pb-10"
        :class="classes(i)"
      >
        <component
          :is="componentLoader(component)"
          :fields="component.fields"
        />
      </div>
    </div>
  </div>
</template>

<script>

  import CallToAction from '@/components/CallToAction'
  import LinkCard from '@/components/LinkCard'

  export default {
    components: {
      CallToAction,
      LinkCard
    },
    props: {
      data: {
        type: Array,
        default: () => []
      }
    },
    data () {
      return {
        componentsMap: [
          {
            name: 'callToAction',
            component: CallToAction
          },
          {
            name: 'linkCard',
            component: LinkCard
          }
        ]
      }
    },
    methods: {
      classes (i) {
        return [
          { 'md:-mt-10 md:pb-20 lg:mt-0 lg:pb-10': i % 2 === 0 },
          { 'lg:-mt-10 lg:pb-20': i % 3 !== 1 }
        ]
      },
      componentLoader (component) {
        const componentName = component.sys.contentType.sys.id
        const matched = this.componentsMap.find(entry => entry.name === componentName)
        return matched ? matched.component : false
      }
    }
  }
</script>
