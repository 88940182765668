<template>
  <div
    class="dropdown-base"
    :class="classes"
  >
    <slot
      name="button-content"
      :toggle="toggle"
    >
      <button
        type="button"
        :class="buttonClasses"
        @click="toggle"
      >
        <slot name="button">
          Select
        </slot>
      </button>
    </slot>
    <transition :name="transitionName">
      <div
        v-show="expanded"
        class="dropdown-container"
      >
        <span class="dropdown-backdrop"></span>
        <div class="dropdown-content">
          <slot :toggle="toggle" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

  export default {
    props: {
      transitionName: {
        type: String,
        default: 'dropdown-transition'
      },
      buttonClasses: {
        type: String,
        default: 'button button-toggle'
      },
      shouldToggle: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        expanded: false
      }
    },
    computed: {
      classes () {
        return [
          { 'is-expanded': this.expanded }
        ]
      }
    },
    watch: {
      expanded (val) {
        if (val) {
          this.$nextTick(() => {
            document.addEventListener('click', this.hide)
            if (this.$device.isMobileOrTablet) { document.addEventListener('touchstart', this.hide) }
          })
          this.$emit('show')
        } else {
          this.$emit('hide')
          document.removeEventListener('click', this.hide)
          document.removeEventListener('touchstart', this.hide)
        }
      }
    },
    methods: {
      toggle (e) {
        if (this.shouldToggle) {
          if (!e.currentTarget.getAttribute('target') || e.currentTarget.getAttribute('target') !== '_blank') {
            e.preventDefault()
          }
          this.expanded = !this.expanded
          this.$store.commit('dropdown/SET_DROPDOWN', this.expanded)
        }
      },
      hide (e) {
        if (!(this.$el === e.target || this.$el.contains(e.target))) {
          this.expanded = false
          this.$store.commit('dropdown/SET_DROPDOWN', false)
        }
      }
    }
  }

</script>

<style lang="scss">

  .dropdown-base {
    @apply relative;

    .button-toggle {
      @apply bg-transparent font-normal rounded-full py-2;
      min-width: 5rem;
      box-shadow: inset 0 0 0 0.125rem theme('colors.gray.200');

      &:hover:not(:disabled) {
        box-shadow: inset 0 0 0 0.125rem theme('colors.gray.600');
      }

    }

    &.is-expanded {
      .button-toggle {
        box-shadow: inset 0 0 0 0.125rem theme('colors.gray.600');
      }
    }

  }

  .dropdown-container {
    @apply absolute left-0 w-full z-40;
    top: 100%;
  }

  .dropdown-backdrop {
    @apply fixed w-full h-full top-0 pointer-events-none hidden;
    background: rgba(0, 0, 0, .25);
    z-index: -1;
  }

  .dropdown-content {
    @apply bg-white shadow rounded mt-4 overflow-hidden;
  }

  .dropdown-transition-enter-active, .dropdown-transition-leave-active {
    transition: opacity .15s ease(easeOutSine), transform .2s ease(easeOutQuad);
  }
  .dropdown-transition-enter, .dropdown-transition-leave-to {
    opacity: 0;
    transform: translateY(-1rem);
  }

  .dropdown-transition-mobile-enter-active, .dropdown-transition-mobile-leave-active {
    transition: transform .25s ease(easeOutQuad);
    .dropdown-backdrop {
      transition: opacity .25s ease(easeOutQuad);
    }
    .dropdown-content {
      transition: transform .25s ease(easeOutQuad);
    }
  }
  .dropdown-transition-mobile-enter, .dropdown-transition-mobile-leave-to {
    .dropdown-backdrop {
      opacity: 0;
    }
    .dropdown-content {
      transform: translateY(100%);
    }
  }

</style>
