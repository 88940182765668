<template>
  <div>
    <h2
      ref="$title"
      class="text-3xl text-center"
    >
      {{ fields.title }}
    </h2>
    <ProductGrid
      :default-options="defaultOptions"
      @filtered="isProductGridInView"
    />
  </div>
</template>

<script>
  import { onMounted, ref } from '@nuxtjs/composition-api'
  import ProductGrid from '@/components/Ecommerce/Products/ProductGrid.vue'
  import { useView } from '@/composables/useView'
  import useUiHelpers from '@/composables/useUiHelpers'

  export default {
    components: {
      ProductGrid
    },
    props: {
      fields: {
        type: Object,
        required: true
      },
      defaultOptions: {
        type: Object,
        required: true
      }
    },
    setup (props) {
      const $title = ref(null)

      const { getFiltersDataFromUrl } = useUiHelpers()

      const {
        isInViewport,
        isBehindOtherElement,
        scrollToElement
      } = useView()

      onMounted(() => {
        if (Object.keys(getFiltersDataFromUrl(true)).length) {
          isProductGridInView()
        }
      })

      const isProductGridInView = () => {
        if (isInViewport($title.value) && !isBehindOtherElement($title.value)) {
          return undefined
        }

        scrollToElement($title.value, 200)
      }

      return {
        isProductGridInView,
        $title
      }
    }
  }
</script>
