<template>
  <div ref="$container">
    <transition
      name="fade"
      mode="out-in"
    >
      <ProductsFilters
        v-if="!loadingFilters || availableFilters.length"
        :sorting="sorting"
        :default-options="defaultOptions"
        :available-filters="availableFilters"
        :filters="query.filters"
        :loading="loading"
        :loading-for="loadingFor"
        @filtered="filtered"
        @sorted="sorted"
      />
      <ProductsFiltersSkeleton
        v-else
        :default-options="defaultOptions"
      />
    </transition>
    <div class="products-list">
      <transition-group
        tag="div"
        name="fade"
        mode="out-in"
      >
        <div
          v-if="!hasProducts && !loading"
          key="no-products"
          class="container px-4 md:px-8"
        >
          <h1 class="text-center text-3xl">
            Geen resultaten
          </h1>
        </div>
        <div
          v-else
          key="products"
          class="container px-4 md:px-8"
        >
          <transition-group
            name="list-transition"
            tag="div"
            class="relative flex flex-wrap -mx-4"
          >
            <slot></slot>
            <div
              v-for="(product, index) in products"
              :key="(product.bundles ? product.bundles[0].id : product.productId) || index"
              v-observe-visibility="(isVisible, entry) => handleVisibilityChange(isVisible, product)"
              class="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 pb-8"
            >
              <call-to-action
                v-if="product.contentType === 'CallToAction'"
                :fields="product"
              />
              <template v-else-if="!defaultOptions.hasBundle">
                <ProductCard
                  v-if="isNaN(product)"
                  :product="product"
                />
                <ProductCardSkeleton v-else />
              </template>
              <template v-else>
                <ProductBundleCard
                  v-if="isNaN(product)"
                  :product="product"
                  :contentful-images="contentfulBundleImages"
                />
                <ProductCardSkeleton v-else />
              </template>
            </div>
          </transition-group>
        </div>
      </transition-group>
      <div class="container px-4 md:px-8">
        <ProductPagination
          :pagination="pagination"
          :loading="loading"
          :loading-for="loadingFor"
          @paginated="paginated"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { computed, onMounted, ref, unref, useRoute, watch } from '@nuxtjs/composition-api'
  import { mapState } from 'vuex'
  import { facetGetters, useFacet } from '@propeller-commerce/propeller'
  import { onSSR } from '@vue-storefront/core'
  import merge from 'lodash.merge'
  import ProductsFilters from '@/components/Ecommerce/Products/ProductsFilters'
  import ProductsFiltersSkeleton from '@/components/Ecommerce/Products/ProductsFiltersSkeleton'
  import ProductPagination from '@/components/Ecommerce/Products/ProductPagination'
  import ProductCard from '@/components/Ecommerce/Products/ProductCard.vue'
  import ProductBundleCard from '@/components/Ecommerce/Products/ProductBundleCard'
  import ProductCardSkeleton from '@/components/Ecommerce/Products/ProductCardSkeleton.vue'
  import CallToAction from '@/components/CallToAction.vue'

  import { useBanners } from '@/composables/useBanners'
  import useUiHelpers from '@/composables/useUiHelpers'
  import { useView } from '@/composables/useView'
  import { useContentfulEntries } from '~/composables/useContentfulEntries'
  import { validProductFilters } from '@/constants/products'

  import { setProductsListTracking } from '~/utils/utils'
  import { useAttribute, useListAttribute } from '~/composables/useAttribute'
  import { productAttributes } from '~/constants/products.module'
  import { gtmSendEvent } from '~/utils/gtm-send-event'

  export default {
    components: {
      ProductPagination,
      ProductCardSkeleton,
      ProductsFiltersSkeleton,
      ProductCard,
      ProductBundleCard,
      CallToAction,
      ProductsFilters
    },
    props: {
      banners: {
        type: Array,
        default: null
      },
      bannerPositions: {
        type: Array,
        default: () => ([6, 16])
      },
      defaultOptions: {
        type: Object,
        default: () => ({})
      }
    },
    setup (props, { emit }) {
      const { getFacetsFromURL, reduceFilters } = useUiHelpers()

      const $container = ref(null)
      const route = useRoute()
      const loadingFor = ref(null)
      const visibleProducts = ref([])

      const trackVisibileProducts = (products) => {
        if (products.length === 4) {
          // we track 1 row of visible products in the grid for event view_item_list

          const productList = setProductsListTracking(products.map(product => ({
            ...product,
            name: product.name[0].value,
            productType: useAttribute(product, productAttributes.brand),
            level: useListAttribute(product, productAttributes.educationType).value.join(', ')
          })))
          gtmSendEvent('view_item_list', {
            ecommerce: {
              currency: 'EUR',
              items: productList
            },
            item_list_name: 'Productpagina',
            item_list_id: 'productpagina'
          }, null, true)
          visibleProducts.value = []
        }
      }
      watch(visibleProducts, () => trackVisibileProducts(visibleProducts.value))

      const handleVisibilityChange = (isVisible, product) => {
        if (isVisible) {
          visibleProducts.value = [...visibleProducts.value, product]
        }
      }
      // This facet is only use to fetch all the available filters
      const {
        result: resultWithoutFiltersApplied,
        search: searchWithoutFilters,
        loading: loadingFilters
      } = useFacet(`${JSON.stringify(props.defaultOptions)}-filters`)

      const {
        result,
        search,
        loading
      } = useFacet(JSON.stringify(props.defaultOptions))

      // The query that's modeled
      const query = ref(merge({
        ...getFacetsFromURL(true),
        categorySlug: 'examenbundel'
      }, props.defaultOptions))

      // The query where all empty and irrelevant (i.e. not a product filter) filters are removed
      const filteredQuery = computed(() => {
        return {
          ...query.value,
          filters: Object.keys(query.value.filters).filter((attribute) => {
            return !!query.value?.filters?.[attribute]?.length && validProductFilters.includes(attribute)
          }).reduce(reduceFilters(query.value.filters), {})
        }
      })

      const parameters = computed(() => {
        const filters = {
          ...query.value.filters,
          sort: query.value.sort,
          page: query.value.page
        }

        const defaultFilters = Object.keys(props.defaultOptions?.filters || {})

        const params = Object.keys(filters).reduce((acc, key) => {
          const values = Array.isArray(filters[key]) ? filters[key] : [filters[key]]
          if (defaultFilters.includes(key)) {
            return acc
          }

          const items = values.map((value) => {
            return `${key}=${value}&`
          }).join('')

          return `${acc}${items}`
        }, '?')

        return params.substring(0, params.length - 1)
      })

      // If someone filtered
      const filtered = (filters) => {
        query.value.page = 1
        query.value.filters = filters.value
        loadingFor.value = filters.trigger
        changeUrl(filters.trigger)
      }

      // If someone paginated
      const paginated = (page) => {
        query.value.page = page
        loadingFor.value = 'PAGINATION'
        changeUrl('PAGINATION')
      }

      const sorted = (sort) => {
        query.value.sort = sort
        loadingFor.value = 'SORTING'
        changeUrl('SORTING')
      }

      const changeUrl = (trigger) => {
        window.history.pushState({
          filters: query.value,
          trigger
        }, '', `${route.value.path}${parameters.value}`)
      }

      const hasProducts = computed(() => !!result.value?.data?.items && result.value.data.items.length > 0)

      const products = computed(() => {
        return hasProducts.value
          ? facetGetters.getProducts(result.value)
          : []
      })

      const getContentfulBundleEntries = async () => {
        return await useContentfulEntries({
          content_type: 'productBundels'
        })
      }

      const contentfulBundleImages = ref(null)
      if (props.defaultOptions.hasBundle) {
        getContentfulBundleEntries().then((entries) => {
          contentfulBundleImages.value = entries.map((entry) => {
            return {
              ...entry?.fields?.mainImage?.fields,
              propellerId: Number(entry?.fields?.propellerId)
            }
          })
        })
      }

      onMounted(() => {
        window.addEventListener('popstate', onPopState)
        return () => {
          window.removeEventListener('popstate', onPopState)
        }
      })

      const onPopState = (event) => {
        if (event.state.filters) {
          query.value = event.state.filters
          loadingFor.value = event.state.trigger
        }
      }

      onSSR(async () => {
        await Promise.all([

          // First make a call without any options applied (except the "default" options that are passed to this component)
          searchWithoutFilters({
            ...getFacetsFromURL(false),
            categorySlug: 'examenbundel',
            sort: 'latest'
          }),

          // Then make a call with the default options and the filters that are selected
          search(filteredQuery.value)
        ])
      })

      const {
        isInViewport,
        scrollToElement
      } = useView()

      const updateProducts = async () => {
        emit('filtered', filteredQuery)
        scrollToTop()
        await search(filteredQuery.value)
      }

      const scrollToTop = () => {
        if (isInViewport($container.value)) {
          return
        }

        scrollToElement($container.value, 200)
      }

      watch(query, updateProducts, { deep: true })

      const pagination = computed(() => facetGetters.getPagination(result.value))
      const sorting = {
        options: [
          {
            label: 'Naam ( A - Z )',
            value: 'shortName_asc'
          },
          {
            label: 'Naam ( Z - A )',
            value: 'shortName_desc'
          },
          {
            label: 'Prijs (oplopend)',
            value: 'price_asc'
          },
          {
            label: 'Prijs (aflopend)',
            value: 'price_desc'
          }
        ],
        selected: filteredQuery.value.sort
      }

      const availableFilters = computed(() => facetGetters.getGrouped(resultWithoutFiltersApplied.value))

      const entries = computed(() => {
        return props.banners && query.value.page === 1
          ? unref(useBanners(products, props.banners, props.bannerPositions))
          : unref(products)
      })

      return {
        result,
        loadingFilters,
        hasProducts,
        entries,
        products,
        pagination,
        loading,
        loadingFor,
        updateProducts,
        availableFilters,
        sorting,
        query,
        filtered,
        paginated,
        sorted,
        $container,
        contentfulBundleImages: computed(() => contentfulBundleImages?.value),
        handleVisibilityChange
      }
    },
    computed: {
      ...mapState({
        config: state => state.config
      })
    }
  }
</script>
