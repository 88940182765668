<template>
  <div>
    <card-base
      class="card-product"
      :interactive="false"
    >
      <header>
        <div class="product-title mt-1 w-full">
          <Skeleton class="h-6 w-full" />
        </div>
        <div class="mt-3 product-subtitle flex justify-center">
          <Skeleton class="h-4 w-36" />
        </div>
        <div class="mt-3 text-gray-600 product-category flex justify-center">
          <Skeleton class="h-4 w-20" />
        </div>
      </header>
      <figure class="product-image mb-16 mt-14 pointer-events-none flex justify-center">
        <Skeleton class="h-48 w-48" />
      </figure>
      <footer>
        <div class="flex justify-between items-end">
          <div class="flex flex-col items-start space-y-2">
            <Skeleton class="h-6 w-20" />
            <Skeleton class="h-6 w-28" />
          </div>
          <div>
            <Skeleton class="h-12 w-12 rounded-full" />
          </div>
        </div>
      </footer>
    </card-base>
  </div>
</template>

<script>
  import CardBase from '@/components/CardBase'
  import Skeleton from '@/components/Skeleton'

  export default {
    components: {
      Skeleton,
      CardBase
    }
  }
</script>

<style lang="scss" scoped>

.card-product {
  @apply h-full p-6 text-center;

  .product-title {
    @apply font-primary text-lg leading-normal truncate;
  }

  .product-category,
  .product-subtitle {
    @apply text-gray-600;
  }

  &.is-minimal {
    @apply p-3;

    .product-title {
      @apply text-base;
    }

    .product-category,
    .product-subtitle {
      @apply text-xs;
    }

    figure {
      @apply mb-0;
    }

    @apply flex flex-row-reverse justify-end items-center;
    .product-image {
      @apply mt-0;
      @apply w-20;
    }

    .product-title,
    .product-category,
    .product-subtitle {
      @apply text-left;
    }

    @screen sm {
      @apply p-6;
      @apply block;
      .product-image {
        @apply mt-6;
        @apply w-auto;
      }
      .product-title,
      .product-category,
      .product-subtitle {
        @apply text-center;
      }
    }
  }

  &.is-active {
    @apply shadow-big;
  }

  &.is-disabled {
    @apply shadow-none;
    @apply border border-gray-100;
  }
}

</style>
