<template>
  <figure
    class="card-image"
    :class="classes"
  >
    <img
      v-if="image"
      :src="image"
      :alt="imageAlt"
    >
  </figure>
</template>

<script>
  export default {
    props: {
      image: {
        type: String,
        default: ''
      },
      alt: {
        type: String,
        default: ''
      },
      aspectRatio: {
        type: String,
        default: '3-2',
        validator: val => ['3-2', '4-3', 'none'].includes(val)
      }
    },
    computed: {
      classes () {
        return `ar-${this.aspectRatio}`
      },
      imageAlt () {
        return this.alt || ''
      }
    }
  }
</script>

<style lang="scss">

  .card-image {
    @apply bg-gray-100 overflow-hidden;

    &.ar-3-2 {
      @include aspect-ratio(3, 2);
    }

    &.ar-4-3 {
      @include aspect-ratio(4, 3);
    }

    img {
      @apply absolute left-0 top-0 w-full h-full object-cover;
      backface-visibility: hidden;
    }

  }

</style>
