<template>
  <div class="flexible-content">
    <component
      :is="componentLoader(component)"
      v-for="(component, i) in parsedContent"
      :key="i"
      :data="component"
    />
  </div>
</template>

<script>

  import CallToActionSection from '@/components/CallToActionSection'
  import RichText from '@/components/RichText'
  import CardsGrid from '@/components/CardsGrid'
  import productOverview from '@/components/FlexibleContent/productOverview'

  export default {
    components: {
      RichText,
      CardsGrid,
      CallToActionSection
    },
    props: {
      content: {
        type: Array,
        default: () => []
      }
    },
    data () {
      return {
        cardComponents: ['linkCard', 'callToAction'],
        componentsMap: [
          {
            name: 'richText',
            component: RichText
          },
          {
            name: 'callToActionSection',
            component: CallToActionSection
          },
          {
            name: 'productOverview',
            component: productOverview
          }
        ]
      }
    },
    computed: {
      parsedContent () {
        const content = []
        let nestedI = -1
        this.content.forEach((component, i) => {
          if (component.sys.contentType) { // this check is to avoid draft items to crash the page
            const componentName = component.sys.contentType.sys.id
            if (this.cardComponents.includes(componentName)) {
              if (Array.isArray(content[nestedI])) {
                content[nestedI].push(component)
              } else {
                content[i] = [component]
                nestedI = i
              }
            } else {
              content.push(component)
              nestedI = -1
            }
          }
        })
        return content.filter(component => component)
      }
    },
    methods: {
      componentLoader (component) {
        if (Array.isArray(component)) return CardsGrid
        const componentName = component.sys.contentType.sys.id
        const matched = this.componentsMap.find(entry => entry.name === componentName)
        return matched ? matched.component : false
      }
    }
  }
</script>

<style lang="scss">

  .flexible-content {

    @screen md {
      + * {
        @apply mt-30;
      }
    }

    .rich-text {
      @apply mt-4;
    }

    .rich-text + .rich-text {
      @apply mt-10;
    }

    .cards-grid {

      @screen md {
        @apply mt-20;
      }

      + .rich-text {
        @apply mt-10;
      }

    }

  }

</style>
