<template>
  <div class="link-card">
    <card-featured
      :title="title"
      :image="image"
      :alt="alt"
      :link="link"
    />
  </div>
</template>

<script>

  import CardFeatured from '@/components/CardFeatured'

  export default {
    components: {
      CardFeatured
    },
    props: {
      fields: {
        type: Object,
        default: () => {},
        required: true
      }
    },
    data () {
      return {
        imageSettings: '?w=480&h=320&fm=jpg&fit=fill&fl=progressive&q=80'
      }
    },
    computed: {
      contentType () {
        if (this.fields.content && this.fields.content.sys.contentType) {
          return this.fields.content.sys.contentType.sys.id
        }
        return 'custom'
      },
      title () {
        if (this.fields.cardTitle) return this.fields.cardTitle
        if (this.fields.content) {
          return this.fields.content.fields.title
        }
        return ''
      },
      link () {
        if (this.contentType === 'custom' || this.fields.cardLink) {
          return this.fields.cardLink
        }
        if (this.fields.content) {
          const slug = this.fields.content.fields.slug
          const parentPage = this.fields.content.fields && this.fields.content.fields.parentPage && this.fields.content.fields.parentPage.fields
          const grandParentPage = parentPage && parentPage.parentPage && parentPage.parentPage.fields
          let prefix = '/'
          if (this.contentType === 'article') prefix += 'artikel/'
          if (grandParentPage) {
            prefix += grandParentPage.slug + '/'
          }
          if (parentPage) {
            prefix += parentPage.slug + '/'
          }
          return prefix + slug
        }
        return ''
      },
      image () {
        if (this.fields.cardImage) {
          return 'https:' + this.fields.cardImage.fields.file.url + this.imageSettings
        }
        if (this.fields.content && this.fields.content.fields && this.fields.content.fields.featuredImage) {
          return 'https:' + this.fields.content.fields.featuredImage.fields.file.url + this.imageSettings
        }
        return ''
      },
      alt () {
        if (!this.image) return ''
        if (!this.fields.cardImage) return ''
        return this.fields.cardImage.fields.description
      }
    }
  }
</script>
